@import url("https://fonts.googleapis.com/css2?family=Domine:wght@400..700&family=Modak&family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap");
body {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: "poppins";
  background-color: #121d3e !important;
}
.project_description{
  font-size: 1rem;
}

.project1 h1,
  .project2 h1,
  .project3 h1,
  .project4 h1 {
    font-size: 2rem;
  }

.icon-container {
  display: flex;
  justify-content: center;
  /* padding-bottom: 40px; */
  text-decoration: none;
  font-size: 300%;
  padding-top: 40px;
  /* position: absolute; */

  /* justify-content: space-around; */
}

.bi-linkedin {
  margin: 10px;
  color: white;
}

.bi-github {
  margin: 10px;
  color: white;
}

.nav-item {
  margin: 23px;
  font-family: "poppins";
  font-weight: 900;
  text-decoration: none;
  font-size: 18px;
  color: #121d3e !important;
  margin-left: auto;
}

a.nav-link {
  color: #121d3e !important;
  margin: 10px;
  text-decoration: none;
}
.navbar-collapse {
  background-color: white;
}

.logo {
  padding-left: 5px;
  height: 30%;
  padding-top: 20px;
}

.navbar {
  display: flex;
  background-color: white !important;
}

.navbar-expand-lg {
  height: 40px;
  background-color: transparent !important;
}

.ml-auto.nav-item.navbar-nav {
  align-items: center;
}

.basic-navbar-nav {
  padding: 10px !important;
}

.bg-light {
  background-color: transparent !important;
}

.navbar-toggler {
  background-color: white !important;
}
.navbar-toggler:hover {
  background-color: white !important;
}
.project1,
.project2,
.project3,
.project4 {
  border: solid black;
  border-radius: 25px;
  margin: 10px;
  padding: 5px;
  display: flex;
  flex-wrap: wrap;
  /* align-items: center !important; */
  /* justify-content: space-around; */
  width: 100%;
  padding: 15px;
  background-color: #f8fbf8;
justify-content: center;
  font-family: "poppins";

  /* font-weight: 900; */
  /* align-items: center; */
  display: flex;
  justify-content: center;
}

.project_status {
  position: absolute;
  margin-bottom: 115px;
  font-weight: 800;
}

.section1 {
  /* margin-bottom: 50px; */
  /* background-color: #ffeff2; */
  width: 100%;
}

.project-container {
  margin-left: 17px;
  /* font-size: 24px; */
  color: white;
  /* padding-top: 30px; */
  /* border: solid black; */
}

.profile-and-typewriter-container {
  display: flex;
  align-items: center;
  justify-content: space-around; /* Adjusts spacing between the image and typing effect */
  margin: 20px 0;
  padding: 20px;
}

.containerpic {
  display: flex;
  flex-direction: row-reverse;
  align-items: center;
  text-align: center;
  margin-right: 20px; /* Add some spacing between the image and the text */
}
.profilpic {
  width: 72%;
  /* border: solid white 8px; */
  border-radius: 50px;
  justify-content: center;
  margin: 30px;
  height: auto;
}

.project-icon {
  font-size: 2rem;
  margin-left: 14px;
  margin-top: 24px;
}

.fav_project_title{
  font-size: 2rem;
}

.typeWriter {
  display: flex;
  justify-content: center;
  /* align-items: flex-start; */
  flex-direction: column;
  width: 100%; /* Allows content to wrap naturally */
  margin: 0 auto;
  text-align: center; /* Centers the text */
}

.lean-more-bttn {
  align-items: center;
  background-color: #121d3e !important;
  font-family: "poppins", sans-serif;
  font-weight: 800;
;
  border: 4px solid #111;
  border-radius: 8px;
  box-sizing: border-box;
  color: #111 !important;
  cursor: pointer;
  display: flex;
  font-size: 12px;
  height: 50px;
  justify-content: center;
  /* line-height: 24px; */
  /* padding: 0 40px; */
  position: relative;
  /* text-align: center !important; */
  text-decoration: none;
  user-select: none;
  /* -webkit-user-select: none; */
  touch-action: manipulation;
  overflow: hidden;
  z-index: 1;
  width: 120px;
}

.lean-more-bttn::after {
  background-color: #fee6e3 !important;
  border-radius: 8px;
  content: "";
  display: block;
  height: 48px;
  width: 100%;
  position: absolute;
  top: -2px;
  left: 0;
  transform: translate(8px, 8px);
  transition: transform 0.2s ease-out;
  z-index: -1;
}

.lean-more-bttn:hover::after {
  transform: translate(0, 0);
}

.lean-more-bttn:active {
  background-color: #ffdeda !important;
  outline: 0;
}

.lean-more-bttn:hover {
  outline: 0;
  color: #111 !important;
}

.btn-primary {
  border: solid 1px #121d3e !important;
}

.grettings {
  font-family: "poppins", sans-serif;
  color: black;
  font-weight: 900;
}


.container-for-projects {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
}

.section2 {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  font-family: "poppins";
  /* padding:50px;; */
  /* background-color: #fff7ea; */
}

.about-me-section {
  padding-bottom: 140px;
}

.mssg {
  border-radius: 10px;
  width: 97%;
  /* padding: 25px 25px 25px 225px; */
  display: flex;
  justify-content: center;
  align-items: center;
  padding-bottom: 10px;
  font-size: 24px;
  font-family: "poppins";
}

.timeline {
  position: relative;
  max-width: 1200px;
  margin: 0 auto;
  /* background-color: yellow; */
}

/* The actual timeline (the vertical ruler) */
.timeline::after {
  content: "";
  position: absolute;
  width: 6px;
  background-color: grey;
  top: 0;
  bottom: 0;
  left: 50%;
  margin-left: -3px;
}

/* Container around content */
.container {
  padding: 10px 50px;
  position: relative;
  background-color: inherit;
  width: 50%;
}
.container.left .content {
  border: 2px solid #000; /* Example: 2px solid black border */
}

.container.right .content {
  border: 2px solid black;
}

/* The circles on the timeline */
.container::after {
  content: "";
  position: absolute;
  width: 25px;
  height: 25px;
  right: -17px;
  background-color: #61d4e8;
  border: 4px solid #2da1e7;
  top: 15px;
  border-radius: 50%;
  z-index: 1;
}

/* Place the container to the left */
.left {
  right: 100px;
}

/* Place the container to the right */
.right {
  left: 50%;
}

/* Add arrows to the left container (pointing right) */
.left::before {
  content: " ";
  height: 0;
  position: absolute;
  top: 22px;
  width: 0;
  z-index: 1;
  right: 30px;
  border: medium solid black;
  border-width: 10px 0 10px 10px;
  border-color: transparent transparent transparent white;
}

.typewrite > .wrap {
  border-right: 0.2em solid white;
  font-family: "poppins", sans-serif;
  font-size: 40px;
  white-space: wrap;
  overflow: hidden;
  font-weight: 900;
  color: white;
}

/* Add arrows to the right container (pointing left) */
/* .right::before {
  content: " ";
  height: 0;
  position: absolute;
  top: 22px;
  width: 0;
  z-index: 1;
  left: 30px;
  border: medium solid white;
  border-width: 10px 10px 10px 0;
  border-color: transparent white transparent transparent;
} */

/* Fix the circle for containers on the right side */
/* .right::after {
  left: -16px;
} */

/* @media screen and (max-width: 600px) {
/* Place the timelime to the left */
/* .timeline::after {
    left: 31px;
  } */

/* Full-width containers */
/* .container {
    width: 100%;
    padding-left: 70px;
    padding-right: 25px;
  } */

/* Make sure that all arrows are pointing leftwards */
/* .container::before {
    left: 60px;
    border: medium solid white;
    border-width: 10px 10px 10px 0;
    border-color: transparent white transparent transparent;
  }

/* Make sure all circles are at the same spot */
/* .left::after, .right::after {
    left: 15px;
  }

  .right {
    left: 0%;
  }
}   */

.section3 {
  text-align: center;
  /* background-color: #ccceec; */
  width: 100%;
}

.section4 {
  text-align: center;
  margin-top: 50px;
  width: 100%;
  height: auto;
  color: white;
}

.section5 {
  width: 100%;
  /* background-color: #fec8b9; */
  font-family: "poppins";
}

footer {
  margin-top: 20px;
}
a{
  text-decoration: none;
}

/* @media (max-width: 768px) {
  .timeline-content {
    width: 80%;
  }

  .left,
  .right {
    transform: translateX(0);
    text-align: center;
  }
} */

.navbar-toggler {
  margin: 10px;
}
@media (max-width: 992px) {
  .navbar-expand-lg .navbar-collapse {
    background-color: transparent !important;
  }

  a.nav-link {
    color: white !important;
  }
}
/* @media (min-width: 820px) {
  .container-for-projects{
    border: solid red 1px;
    width: 80%;
    justify-content: center !important;
    flex-direction: column !important;
    margin: 55px;;
  }
  
} */
/* @media (min-width: 535px) and (max-width: 826px) and (min-height: 525px) and (max-height: 525px) {
  .container-for-projects{
    border: solid red 1px;
    width: 80%;
    justify-content: center !important;
    flex-direction: column !important;
    margin: 55px;;;
  }
} */


   
  /* .container-for-projects {
    display: flex;
    height: auto;
    border: solid pink 1px;
    width: 80%;
    margin: 55px; */
    /* width: max-content; */
    /* height: max-content; */
    /* justify-content: center !important; */
    /* flex-direction: column!important; */
    /* margin: 55px; */
    /* @media (max-width:  992px) */
  /* } */
  .project1,
  .project2,
  .project3,
  .project4 {
    /* width: fit-content; */
    justify-content: center;
  }

@media (min-width: 1024px){
  .container-for-projects{
    display: flex !important;
    flex-direction: row !important;

}
  .project1 h1,
  .project2 h1,
  .project3 h1,
  .project4 h1 {
    font-size: 1.4rem;
  }
  .project-icon{
    margin-top:9px;

  }
}


@media (min-width: 768px) and (max-width:1023px) {
  .container-for-projects{
    
      display: flex;
      flex-direction: column;
      width: 70%;
      align-items: center !important;
      margin-left: 100px;
      /* margin: 70px; */
      /* margin: 50px; */
    }
    
    .profile-and-typewriter-container{
      flex-direction: column;   
      /* border: solid red 1px;  */
      margin-right: 40px;
      align-items: center;
      justify-content: center !important;

       
    }


     a{
      text-decoration: none;
    }
    .profilpic{
      /* width:40%;
      height: 20% ; */
      justify-content: center !important;

    }

    .containerpic{
      display: flex; 
      justify-content: space-around;
    }
  }


@media (max-width: 768px) {
  .typewrite > .wrap {
    font-size: 24px;
  }
  .cursor {
    font-size: 18px;
  }
  .navbar {
    width: 100%;
  }
  .section4 {
    text-align: center;
    /* margin-top: 50px; */
    /* width: 100%;   */
    height: auto;
    color: white;
  }

  .footerMssg {
    padding: 0;
  }

  .container-for-projects {
    display: flex;
    flex-direction: column;
  }
  
  .project1,
  .project2,
  .project3,
  .project4 {
    width: fit-content;
    justify-content: center;
  }
  .d-inline-block {
    width: 100%;
  }
  .project_status {
    margin-bottom: 76px;
  }
  .profile-and-typewriter-container{
    flex-direction: column;      
  }
  a{
    text-decoration: none;
  }
}
